"use client"
import React, { useState } from "react"
import Markdown from "react-markdown"
import { CheckIcon, CopyIcon } from "@radix-ui/react-icons"
import { IoCheckmark } from "react-icons/io5"

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
// Other light styles to consider: base16AteliersulphurpoolLight, materialLight, coldarkCold
// Other dark styles to consider: tomorrow, okaidia, a11yDark, atomDark, cb, coldarkDark, dracula, vscDarkPlus
// Full list
import { coldarkDark as themeDark, base16AteliersulphurpoolLight as themeLight } from "react-syntax-highlighter/dist/esm/styles/prism"
import { useTheme } from "next-themes"

export default function CodeBlock({
  content,
  highlightLineNumber,
  startingLineNumber = 1,
  isLoading = false,
  maxLines = 10,
  isCode = true,
}) {
  const [copied, setCopied] = useState(false)

  const handleCopyClick = () => {
    const lines = content.split("\n")
    lines.shift() // Remove the first line
    if (lines[lines.length - 1] === "~~~") {
      lines.pop() // Remove the last line if it is ~~~
    }
    const clipboardContent = lines.join("\n")
    navigator.clipboard.writeText(clipboardContent).then(() => {
      setCopied(true)
    })
    setTimeout(() => setCopied(false), 3000) // Revert icon back after 3 seconds
  }

  let editorStyle: any
  const isDarkTheme = useTheme().theme === "dark"
  if (isDarkTheme) {
    editorStyle = themeDark
  } else {
    editorStyle = themeLight
  }
  if (isLoading) {
    return (
      <Markdown
        components={{
          code(props) {
            return (
              <SyntaxHighlighter style={editorStyle} className="text-sm rounded">
                Loading...
              </SyntaxHighlighter>
            )
          },
        }}>
        {`~~~\nLoading...\n~~~`}
      </Markdown>
    )
  }

  if (isCode) {
    // Split content into lines and store the first line as a variable called firstLine and remove it from the content
    const lines = content.split("\n")
    const firstLine = lines[0]
    lines.shift()
    const lastLine = lines[lines.length - 1]
    lines.pop()

    // Calculate the start and end lines to display
    const halfMaxLines = Math.floor(maxLines / 2)
    const startLine = Math.max(highlightLineNumber - halfMaxLines - startingLineNumber + 1, 0)
    const endLine = Math.min(highlightLineNumber + halfMaxLines - startingLineNumber + 1, lines.length - 1)

    // Slice the content to include only the lines within the calculated range and re-add the first and last line
    content = `${firstLine}\n${lines.slice(startLine, endLine + 1).join("\n")}\n${lastLine}`
    startingLineNumber = startingLineNumber + startLine
  }

  return (
    <div className="mt-2 mb-2 rounded">
      <div className="relative">
        <button
          onClick={handleCopyClick}
          className={`absolute top-2 right-2 text-white font-bold py-2 px-2 rounded ${
            copied ? "bg-blue-600" : "bg-blue-500 hover:bg-blue-600"
          }`}>
          {copied ? <IoCheckmark className="text-green-200 dark:text-green-300" size={15} /> : <CopyIcon />}
        </button>
      </div>

      <Markdown
        components={{
          code(props) {
            const { children, className, node, ...rest } = props
            const match = /language-(\w+)/.exec(className || "")
            return match ? (
              <SyntaxHighlighter
                showLineNumbers={true}
                customStyle={{
                  fontSize: "12px",
                }}
                wrapLines={true}
                codeTagProps={{
                  fontSize: "inherit",
                }}
                className="text-sm rounded border"
                {...rest}
                PreTag="div"
                lineProps={(lineNumber) => {
                  const style: any = { display: "block", width: "fit-content" }
                  if (lineNumber === highlightLineNumber) {
                    style.backgroundColor = isDarkTheme ? "#4f4e4e" : "#ffbf9e"
                  }
                  return { style }
                }}
                language={match[1]}
                style={editorStyle}
                startingLineNumber={startingLineNumber} // Adjust starting line number
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              <code {...rest} className={className + " text-sm "}>
                {children}
              </code>
            )
          },
        }}>
        {content || ""}
      </Markdown>
    </div>
  )
}
