import React from "react"
import { useReplayStore } from "./store"
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { FaCheck } from "react-icons/fa"

const CATEGORIES: { [key: string]: string[] } = {
  userRole: ["Finance", "Admin"],
  account: ["Smith", "Forward California", "Hope", "Rising Blue"],
  browser: ["Chrome", "Firefox", "Safari"],
  clickTag: ["button", "input", "nav", "text", "link", "div", "span", "a", "img", "svg", "form"],
}

interface ReplaysFilterOptionsProps {
  category: string
  setOpen: (open: boolean) => void
}

const ReplaysFilterOptions: React.FC<ReplaysFilterOptionsProps> = ({ category, setOpen }) => {
  const { filterCategory, setFilterCategory } = useReplayStore()
  const categoryOptions = CATEGORIES[category]

  const handleCategorySelect = (item: string) => {
    setFilterCategory({
      ...filterCategory,
      [category]: {
        ...filterCategory[category],
        value: item,
      },
    })

    setOpen(false)
  }

  return (
    <Command>
      <CommandInput placeholder="Filter" />
      <CommandList>
        <CommandGroup>
          {categoryOptions.map((item) => (
            <CommandItem key={item} value={item}>
              <div className="flex items-center space-x-2">
                <div onClick={() => handleCategorySelect(item)} className="cursor-pointer">
                  <div className="text-sm px-2">{item}</div>
                </div>
                {item === filterCategory[category]?.value ? <FaCheck className="w-3 h-3" /> : null}
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}

export default ReplaysFilterOptions
