"use client"

import ReplayToolbar from "./replay-toolbar"
import { useState, useMemo } from "react"
import ReplaySkeleton from "./replay-skeleton"
import dynamic from "next/dynamic"
import { GroupPreview, ReplayEnvironmentFilterOptions, ReplaySortOptions } from "./types"
import { PAGE_SIZE } from "./constants"

const ReplayCardWrapper = dynamic(() => import("./replay-card-wrapper"), {
  ssr: false,
  loading: () => <ReplaySkeleton />,
})

interface ReplayContainerProps {
  initialData: GroupPreview[]
  hasMore: boolean
  isDemo: boolean
}

const ReplayContainer: React.FC<ReplayContainerProps> = ({ initialData, hasMore, isDemo }) => {
  const [data, setData] = useState<GroupPreview[]>(initialData)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [loading, setLoading] = useState<boolean>(false)
  const [allIssuesLoaded, setAllIssuesLoaded] = useState<boolean>(!hasMore)
  const [page, setPage] = useState<number>(1)
  const [selectedSorter, setSelectedSorter] = useState<ReplaySortOptions>("Last Seen")
  const [selectedFilterType, setSelectedFilterType] = useState<{
    impact: string[]
    endpoint: string[]
    environment: ReplayEnvironmentFilterOptions
  }>({
    impact: [],
    endpoint: [],
    environment: null,
  })

  const fetchData = async (filterCategory?: { impact: string[]; endpoint: string[]; environment: ReplayEnvironmentFilterOptions }) => {
    const startTime = performance.now()
    setLoading(true)
    const newPage = Math.ceil((data.length + 1) / PAGE_SIZE)
    if (newPage > totalPages) {
      setTotalPages(totalPages + 1)
      setPage(newPage)
    }

    const earliestTimestamp = !filterCategory && data.length > 0 ? data[data.length - 1].most_recent_high_severity_time : null

    const filter = filterCategory || selectedFilterType

    const response = await fetch("/issues/fetch", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        earliestTimestamp,
        filterCategory: filter,
      }),
    })
    const result = await response.json()
    if (result.success) {
      if (!filterCategory) {
        const updatedData = [...data, ...result.signatures]
        setData(updatedData)
      } else {
        setData(result.signatures)
      }
      setAllIssuesLoaded(!result.hasMore)
      setLoading(false)
    }
    const endTime = performance.now()
    console.log(`Time taken to fetch signatures: ${endTime - startTime}ms`)
  }

  const handleFilterChange = (filterCategory: { impact: string[]; endpoint: string[]; environment: ReplayEnvironmentFilterOptions }) => {
    setSelectedFilterType(filterCategory)
    fetchData(filterCategory)
  }

  const handleSorterChange = (newSorter: ReplaySortOptions) => {
    setSelectedSorter(newSorter)
  }

  const filteredSignatures = useMemo(() => {
    const getSortDate = (group: GroupPreview) => {
      return group.most_recent_high_severity_time
        ? new Date(group.most_recent_high_severity_time).getTime()
          : Number.POSITIVE_INFINITY
    }

    const sortData = (data: GroupPreview[]) => {
      switch (selectedSorter) {
        case "Last Seen":
          return data.sort((a, b) => getSortDate(b) - getSortDate(a))
        case "First Seen":
          return data.sort((a, b) => getSortDate(a) - getSortDate(b))
        case "Users":
          return data.sort((a, b) => (0 || 1) - (0 || 1))
        default:
          return data
      }
    }

    const sortedData = sortData([...data])

    setTotalPages(Math.ceil(sortedData.length / PAGE_SIZE))
    setPage(Math.ceil(sortedData.length / PAGE_SIZE))
    return sortedData
  }, [selectedFilterType, data, selectedSorter])

  return (
    <div>
      <ReplayToolbar
        selectedFilterType={selectedFilterType}
        setSelectedFilterType={handleFilterChange}
        selectedSorter={selectedSorter}
        setSelectedSorter={handleSorterChange}
        isDemo={isDemo}
      />

      <ReplayCardWrapper
        page={page}
        setPage={setPage}
        fetchData={fetchData}
        pageSize={PAGE_SIZE}
        allIssuesLoaded={allIssuesLoaded}
        filteredSignatures={filteredSignatures}
        loading={loading}
        totalPages={totalPages}
      />
    </div>
  )
}

export default ReplayContainer
