import { Input } from "@/components/ui/input"
import { useEffect, useState } from "react"

interface SearchBoxProps {
  onSearch: (query: string) => void
  placeholder?: string
  delay?: number
  className?: string
}

export function SearchBox({ onSearch, placeholder = "Search...", delay = 300, className }: SearchBoxProps) {
  const [searchQuery, setSearchQuery] = useState("")
  const [debouncedValue, setDebouncedValue] = useState(searchQuery)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(searchQuery), delay)
    return () => clearTimeout(timer)
  }, [searchQuery, delay])

  useEffect(() => {
    onSearch(debouncedValue)
  }, [debouncedValue, onSearch])

  return (
    <Input
      type="search"
      placeholder={placeholder}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className={`w-[200px] md:w-[300px] ${className}`}
      data-testid="search-box"
    />
  )
}
