import { REPLAYS_FILTERS_TYPE } from "./constants"
import ReplaysFilterLengthOptions from "./replays-filter-length-options"
import FilterSearch from "@/components/shared/filters/filter-search"
import ReplaysFilterOptions from "./replays-filter-options"
import { Dispatch, SetStateAction } from "react"

interface RenderFilterCategoryOptionsProps {
  category: string
  setOpen: (open: boolean) => void
  filterCategory: REPLAYS_FILTERS_TYPE
  setFilterCategory: Dispatch<SetStateAction<REPLAYS_FILTERS_TYPE>>
}

const RenderFilterCategoryOptions: React.FC<RenderFilterCategoryOptionsProps> = ({
  category,
  setOpen,
  filterCategory,
  setFilterCategory,
}) => {
  if (category === "length") {
    return <ReplaysFilterLengthOptions category={category} setOpen={setOpen} />
  } else if (category === "endpoint" || category === "user" || category === "clickText" || category === "clickCssClass") {
    return (
      <FilterSearch setOpen={setOpen} selectedCategory={category} filterCategory={filterCategory} setFilterCategory={setFilterCategory} />
    )
  } else return <ReplaysFilterOptions category={category} setOpen={setOpen} />
}

export default RenderFilterCategoryOptions
