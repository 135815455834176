import { RxCross2 } from "react-icons/rx"
interface FilterRemoveButtonProps {
  onClick: (filterKey: string) => void
  filterKey: string
}
const FilterRemoveButton: React.FC<FilterRemoveButtonProps> = ({ onClick, filterKey }) => (
  <RxCross2
    className="mx-2 cursor-pointer text-gray-500 hover:text-gray-700"
    onClick={(e) => {
      e.stopPropagation()
      onClick(filterKey)
    }}
    aria-label="Remove filter"
  />
)
export default FilterRemoveButton
