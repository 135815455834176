interface FilterTitleProps {
  label: string
  isEquality?: boolean
}

const FilterTitle: React.FC<FilterTitleProps> = ({ label, isEquality = false }) => (
  <>
    <span className="font-semibold px-3 py-1 bg-gray-200 dark:bg-zinc-900 border-r border-gray-300 dark:border-gray-600">
      {label && label.length > 0 ? label.charAt(0).toUpperCase() + label.slice(1) : label}
    </span>
    <span className="px-3 py-1 border-r border-gray-300 dark:border-gray-600">{isEquality ? "is" : "contains"}</span>
  </>
)
export default FilterTitle
