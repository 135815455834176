import { LENGTH_TIME_UNITS, REPLAYS_ACCOUNT, REPLAYS_BROWSER, REPLAYS_CLICK_TAG, REPLAYS_USER_ROLE } from "./types"

export const SESSIONS_PAGE_SIZE = 9

export const INITIAL_PAGE_SIZE = 1 // Only load 1 replay initially for faster initial page load
import { MdCss, MdHtml } from "react-icons/md";

import { 
  PiUsers, 
  PiLink, 
  PiTimer, 
  PiUserGear, 
  PiBank, 
  PiBrowser, 
  PiHand, 
  PiTextT 
} from "react-icons/pi";

export const FILTERS: {
  user: { value: string; label: string; icon: JSX.Element; isForDemoOnly: boolean }
  endpoint: { value: string; label: string; icon: JSX.Element; isForDemoOnly: boolean }
  length: {
    value: {
      type: "< Less Than" | "> Greater Than"
      unit: LENGTH_TIME_UNITS
      value: string
    }
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }
  userRole: {
    value: REPLAYS_USER_ROLE | ""
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }
  account: {
    value: REPLAYS_ACCOUNT | ""
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }
  browser: {
    value: REPLAYS_BROWSER | ""
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }
  clickTag: {
    value: REPLAYS_CLICK_TAG | ""
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }
  clickText: {
    value: string
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }
  clickCssClass: {
    value: string
    label: string
    icon: JSX.Element;
    isForDemoOnly: boolean
  }  
} = {
  user: { value: "All Users", label: "User", icon: <PiUsers size={18} />, isForDemoOnly: false },
  endpoint: { value: "", label: "URL Visited", icon: <PiLink size={18} />, isForDemoOnly: false },
  clickText: { value: "", label: "Click Text", icon: <PiTextT size={18} />, isForDemoOnly: false },  
  clickTag: { value: "", label: "Click Tag", icon: <MdHtml size={22} />, isForDemoOnly: false },
  clickCssClass: { value: "", label: "Click CSS Class", icon: <MdCss size={23} />, isForDemoOnly: false },  
  length: {
    value: {
      type: "> Greater Than",
      unit: "seconds",
      value: "",
    },
    label: "Length",
    icon: <PiTimer size={18} />,
    isForDemoOnly: false,
  },
  userRole: { value: "", label: "User Role", icon: <PiUserGear size={18} />, isForDemoOnly: true },
  account: { value: "", label: "Account", icon: <PiBank size={18} />, isForDemoOnly: true },
  browser: { value: "", label: "Browser", icon: <PiBrowser size={18} />, isForDemoOnly: true },
};
export type REPLAYS_FILTERS_TYPE = typeof FILTERS
