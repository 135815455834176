import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { REPLAYS_FILTERS_TYPE } from "@/app/replays/constants"
import { FilterSearchFooter, FilterSearchInput } from "./"

const placeholders: { [key: string]: string } = {
  user: "Search user",
  endpoint: "Search endpoint",
  clickText: "Search click text",
  clickCssClass: "Search click CSS class"
}

interface FilterSearchProps {
  setOpen: (open: boolean) => void
  handleSelect?: (category: string, subCategory: string) => void
  selectedFilterType?: {
    impact: string[]
    endpoint: string[]
  }
  selectedCategory?: string | null
  filterCategory?: REPLAYS_FILTERS_TYPE
  setFilterCategory?: Dispatch<SetStateAction<REPLAYS_FILTERS_TYPE>>
}

const FilterSearch: React.FC<FilterSearchProps> = ({
  setOpen,
  handleSelect,
  selectedFilterType,
  selectedCategory,
  filterCategory,
  setFilterCategory,
}) => {
  const [inputValue, setInputValue] = useState(selectedFilterType?.endpoint[0] ?? "")

  useEffect(() => {
    if (filterCategory && selectedCategory) {
      if (selectedCategory === "user" || selectedCategory === "endpoint" || selectedCategory === "clickText" || selectedCategory === "clickCssClass") {
        setInputValue(filterCategory?.[selectedCategory]?.value ?? "")
      }
    }
  }, [filterCategory, selectedCategory])

  const handleFilterType = (type: "user" | "endpoint" | "clickText" | "clickCssClass") => {
    if (setFilterCategory && filterCategory) {
      setFilterCategory({
        ...filterCategory,
        [type]: { ...filterCategory[type], value: inputValue },
      })
    }
  }

  const handleSearch = () => {
    if ((selectedCategory && selectedCategory === "user") || selectedCategory === "endpoint" || selectedCategory === "clickText" || selectedCategory === "clickCssClass") {
      handleFilterType(selectedCategory)
    }
    handleSelect && handleSelect("endpoint", inputValue)
    setOpen(false)
  }

  const placeholder = placeholders[selectedCategory ?? ""]

  return (
    <div className="flex flex-col space-y-2 m-2">
      <FilterSearchInput inputValue={inputValue} setInputValue={setInputValue} handleSearch={handleSearch} placeholder={placeholder} />
      <FilterSearchFooter />
    </div>
  )
}

export default FilterSearch
