import { Input } from "@/components/ui/input"
import React, { useEffect, useRef } from "react"

interface FilterSearchInputProps {
  inputValue: string
  setInputValue: (value: string) => void
  handleSearch: () => void
  placeholder: string
}

const FilterSearchInput: React.FC<FilterSearchInputProps> = ({ inputValue, setInputValue, handleSearch, placeholder }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current?.focus()
  }, [])
  return (
    <Input
      type="text"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={(e) => e.key === "Enter" && handleSearch()}
      placeholder={placeholder}
      className="flex-grow max-w-72 bg-gray-100 dark:bg-gray-950 border border-gray-200 dark:border-gray-800 shadow-sm"
      ref={inputRef}
    />
  )
}

export default FilterSearchInput
