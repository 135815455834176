"use client"

import { Button } from "@/components/ui/button"
import { useState } from "react"
import { LuListFilter } from "react-icons/lu"
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import ReplayFilterOptions from "./replay-filter-options"
import ReplaySort from "./replay-sort"
import { ReplayEnvironmentFilterOptions, ReplayFilterOptionsType, ReplaySortOptions } from "./types"
import { FilterSection } from "@/components/shared/filters"
import { SearchBox } from "@/components/shared/searchbox"

const filterOptions: { value: ReplayFilterOptionsType; label: string }[] = [
  { value: "impact", label: "Filter by Impact" },
  { value: "endpoint", label: "Filter by URL Visited" },
  { value: "environment", label: "Filter by Environment" },
]

interface SelectedFilterType {
  impact: string[]
  endpoint: string[]
  environment: ReplayEnvironmentFilterOptions
}

interface ReplayToolbarProps {
  selectedFilterType: SelectedFilterType
  setSelectedFilterType: (filterType: SelectedFilterType) => void
  selectedSorter: ReplaySortOptions
  setSelectedSorter: (newSorter: ReplaySortOptions) => void
  isDemo: boolean
}

const ReplayToolbar = ({ selectedFilterType, setSelectedFilterType, selectedSorter, setSelectedSorter, isDemo }: ReplayToolbarProps) => {
  const [open, setOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)

  const handleCategorySelect = (category: string, subCategory: string) => {
    const updatedFilter = { ...selectedFilterType }

    if (category === "impact") {
      if (!updatedFilter.impact.includes(subCategory)) {
        updatedFilter.impact.push(subCategory)
      } else {
        updatedFilter.impact = updatedFilter.impact.filter((item) => item !== subCategory)
      }
    } else if (category === "endpoint") {
      updatedFilter.endpoint[0] = subCategory
    } else if (category === "environment") {
      updatedFilter.environment = updatedFilter.environment === "Production Only" ? null : "Production Only"
    }

    setSelectedFilterType(updatedFilter)
  }

  const handleSearch = (query: string) => {
    console.log("search query", query)
  }

  const handlePopoverClose = (open: boolean) => {
    if (!open) {
      setSelectedCategory(null)
    }
    setOpen(open)
  }

  const renderOptions = (selectedCategory: string | null, handlePopoverOpenChange?: (open: boolean) => void) => {
    if (selectedCategory === null) {
      return (
        <Command>
          <CommandInput placeholder="Filter" />
          <CommandList>
            <CommandGroup>
              {filterOptions.map((option) => (
                <CommandItem key={option.value} className="my-2 mx-1">
                  <div
                    className="flex items-center space-x-2 cursor-pointer "
                    onClick={() => {
                      setSelectedCategory(option.value)
                    }}>
                    <div className="text-sm px-2 py-1">{option.label}</div>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      )
    }

    return (
      <ReplayFilterOptions
        category={selectedCategory}
        selectedFilterType={selectedFilterType}
        handleSelect={handleCategorySelect}
        setOpen={handlePopoverOpenChange || handlePopoverClose}
      />
    )
  }

  return (
    <div className="flex items-center justify-between space-x-4 mb-4">
      <div className="flex items-center justify-center">
        <div className="mr-4">
          <Popover open={open} onOpenChange={handlePopoverClose}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className={`justify-around ${
                  selectedFilterType.impact.length === 0 && selectedFilterType.endpoint.length === 0 ? "w-auto" : "w-14"
                }`}
                onClick={() => setOpen(!open)}>
                <LuListFilter className="w-4 h-4" />
                {selectedFilterType.impact.length === 0 && selectedFilterType.endpoint.length === 0 && <p className="ml-2">Filter</p>}
              </Button>
            </PopoverTrigger>

            <PopoverContent className="p-0 max-h-60 w-48 ml-8 2xl:ml-[8rem]">{renderOptions(selectedCategory)}</PopoverContent>
          </Popover>
        </div>
        {isDemo && <SearchBox onSearch={handleSearch} placeholder="Search issues..." className="mr-4" />}
        <FilterSection
          selectedFilterType={selectedFilterType}
          setSelectedFilterType={setSelectedFilterType}
          renderOptions={renderOptions}
        />
      </div>
      <div>
        <ReplaySort selectedSorter={selectedSorter} setSelectedSorter={setSelectedSorter} />
      </div>
    </div>
  )
}

export default ReplayToolbar
