const ReplaySkeleton = () => {
  const render = (count: number) => {
    return [...Array(count)].map((_, index) => (
      <ReplaySkeletonSingleElement key={index} />
    ));
  };
  return (
    <div className="grid 2xl:grid-cols-2 grid-cols-1 gap-8">{render(10)}</div>
  );
};

const ReplaySkeletonSingleElement = () => {
  return (
    <div className="flex border rounded-lg shadow-lg overflow-hidden h-[350px] 2xl:w-auto animate-pulse bg-white dark:bg-black">
      <div className="w-1/2 p-4 flex flex-col">
        <div className="flex">
          <div className="h-6 w-28 bg-gray-200 mb-4 rounded dark:bg-slate-900"></div>
          <div className=" h-6 w-12 bg-gray-200 rounded ml-2 dark:bg-slate-900"></div>
        </div>
        <div className="h-28 bg-gray-200 mb-2 rounded dark:bg-slate-900"></div>
        <div className=" h-28 bg-gray-200 mb-4 rounded dark:bg-slate-900"></div>
        <div className="flex flex-wrap">
          <div className=" h-6 w-12 bg-gray-200 mb-1 mr-1 rounded dark:bg-slate-900"></div>
          <div className=" h-6 w-44 bg-gray-200 mb-1 mr-1 rounded dark:bg-slate-900"></div>
          <div className=" h-6 w-44 bg-gray-200 mb-1 mr-1 rounded dark:bg-slate-900"></div>
        </div>
      </div>
      <div className="w-1/2 my-4 mr-4 bg-gray-200 rounded dark:bg-slate-900"></div>
    </div>
  );
};

export { ReplaySkeletonSingleElement };

export default ReplaySkeleton;
