"use client";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import React, { useEffect, useState } from "react";
import { BiSortAlt2 } from "react-icons/bi";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { ReplaySortOptions } from "./types";

const sortOptions: { value: ReplaySortOptions; label: string }[] = [
  { value: "Last Seen", label: "Last time the issue occurred" },
  { value: "First Seen", label: "First time the issue occurred" },
  { value: "Users", label: "Number of users affected" },
];

interface ReplaySortProps {
  selectedSorter: ReplaySortOptions;
  setSelectedSorter: (newSorter: ReplaySortOptions) => void;
}

const ReplaySort = ({ selectedSorter, setSelectedSorter }: ReplaySortProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            onClick={() => setOpen(!open)}>
            <BiSortAlt2 className="mr-2 h-4 w-4" />
            {selectedSorter}
            {!open ? (
              <FaChevronDown className="ml-2" />
            ) : (
              <FaChevronUp className="ml-2" />
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 max-h-60 w-56 mr-8 2xl:mr-[4.5rem]">
          <Command>
            <CommandList>
              <CommandGroup>
                {sortOptions.map((option) => (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      setSelectedSorter(option.value);
                      setOpen(false);
                    }}
                    className="cursor-pointer">
                    <div className="flex flex-row space-x-2 ">
                      <div className="flex items-center justify-center">
                        {selectedSorter === option.value ? (
                          <FaCheck className="w-4 h-4" />
                        ) : (
                          <div className="w-4 h-4"></div>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <div className="text-sm">{option.value}</div>
                        <div className="text-xs">{option.label}</div>
                      </div>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ReplaySort;
