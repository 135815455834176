"use client"

import { useState } from "react"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { LENGTH_TIME_UNITS } from "./types"

interface CustomTimeInputProps {
  customTime: string
  setCustomTime: (value: string) => void
  handleCustomTimeSubmit: (unit: LENGTH_TIME_UNITS) => void
}
const CustomTimeInput = ({ customTime, setCustomTime, handleCustomTimeSubmit }: CustomTimeInputProps) => {
  const [selectedUnit, setSelectedUnit] = useState<LENGTH_TIME_UNITS | "">("")

  const handleUnitChange = (value: LENGTH_TIME_UNITS) => {
    setSelectedUnit(value)
    handleCustomTimeSubmit(value)
  }

  return (
    <div className="flex space-x-2 items-center">
      <Input
        type="number"
        placeholder="Time"
        value={customTime}
        onChange={(e) => {
          const value = parseInt(e.target.value.trim())
          setCustomTime(Math.max(1, Math.min(999, value)).toString())
        }}
        className="w-24"
        min="1"
        max="999"
      />
      <Select value={selectedUnit} onValueChange={handleUnitChange}>
        <SelectTrigger className="w-[100px]">
          <SelectValue placeholder="Unit" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="seconds">Seconds</SelectItem>
          <SelectItem value="minutes">Minutes</SelectItem>
          <SelectItem value="hours">Hours</SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}

export default CustomTimeInput
