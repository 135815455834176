"use client"

import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { FaCheck } from "react-icons/fa6"
import { LENGTH_TIME_UNITS } from "./types"
import { useState } from "react"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import CustomTimeInput from "./replays-custom-time-input"
import { useReplayStore } from "./store"

const categories = ["> Greater Than", "< Less Than"] as const
type Category = (typeof categories)[number]

const timeOptions = [
  { label: "3 seconds", value: 3, unit: "seconds" },
  { label: "1 minute", value: 1, unit: "minute" },
  { label: "30 minutes", value: 30, unit: "minutes" },
  { label: "1 hour", value: 1, unit: "hour" },
] as const

interface ReplaysFilterLengthOptionsProps {
  category: string
  setOpen: (open: boolean) => void
}

const getUnit = (value: string, customTime: string): LENGTH_TIME_UNITS => {
  if (customTime === "1") {
    switch (value) {
      case "seconds":
        return "second" as LENGTH_TIME_UNITS
      case "minutes":
        return "minute" as LENGTH_TIME_UNITS
      case "hours":
        return "hour" as LENGTH_TIME_UNITS
      default:
        return value as LENGTH_TIME_UNITS
    }
  }
  return value as LENGTH_TIME_UNITS
}

const ReplaysFilterLengthOptions = ({ category, setOpen }: ReplaysFilterLengthOptionsProps) => {
  const { filterCategory, setFilterCategory } = useReplayStore()
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
  const [customTime, setCustomTime] = useState<string>("")

  const handleCategoryClick = (item: Category) => {
    setSelectedCategory(item)
  }

  const handleTimeOptionClick = (value: number, unit: LENGTH_TIME_UNITS) => {
    const unitValue = getUnit(unit, value.toString())
    if (selectedCategory) {
      setFilterCategory({
        ...filterCategory,
        length: {
          ...filterCategory.length,
          value: {
            type: selectedCategory,
            unit: unitValue,
            value: value.toString(),
          },
        },
      })
    }
    setOpen(false)
  }

  const handleCustomTimeSubmit = (unit: LENGTH_TIME_UNITS) => {
    if (selectedCategory && customTime) {
      const value = parseInt(customTime)
      handleTimeOptionClick(value, unit)
    }
  }

  return (
    <Command>
      <CommandInput placeholder="Filter" />
      <CommandList>
        {!selectedCategory && (
          <div className="p-2">
            {categories.map((item) => (
              <CommandItem key={item} value={item}>
                <div className="flex items-center space-x-2">
                  <div onClick={() => handleCategoryClick(item)} className="cursor-pointer">
                    <div className="text-sm px-2">{item}</div>
                  </div>
                  {category === "length" && item === filterCategory.length.value.type ? <FaCheck className="w-4 h-4" /> : null}
                </div>
              </CommandItem>
            ))}
          </div>
        )}
        {selectedCategory ? (
          <>
            <div className="p-2">
              <Select value={selectedCategory || ""} onValueChange={(value) => setSelectedCategory(value as Category)}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select category" />
                </SelectTrigger>
                <SelectContent>
                  {categories.map((item) => (
                    <SelectItem key={item} value={item}>
                      {item}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <CommandGroup heading={`Enter custom time`}>
              <CustomTimeInput customTime={customTime} setCustomTime={setCustomTime} handleCustomTimeSubmit={handleCustomTimeSubmit} />
            </CommandGroup>
            <CommandGroup heading={`Select time (${selectedCategory})`}>
              {timeOptions.map((option) => (
                <CommandItem key={option.value} value={option.label}>
                  <div onClick={() => handleTimeOptionClick(option.value, option.unit)} className="cursor-pointer">
                    <div className="text-sm px-2">{option.label}</div>
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </>
        ) : null}
      </CommandList>
    </Command>
  )
}

export default ReplaysFilterLengthOptions
