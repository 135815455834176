import { create } from "zustand"
import { Dispatch, SetStateAction } from "react"
import { FILTERS, REPLAYS_FILTERS_TYPE } from "./constants"
import { SessionReplay } from "./data-fetch"
import { ReplayUser } from "./types"

interface ReplayStore {
  filterCategory: REPLAYS_FILTERS_TYPE
  setFilterCategory: Dispatch<SetStateAction<REPLAYS_FILTERS_TYPE>>
  replays: SessionReplay[]
  setReplays: Dispatch<SetStateAction<SessionReplay[]>>
  activeUser: string | null
  setActiveUser: Dispatch<SetStateAction<string | null>>
  filteredUsers: ReplayUser[]
  setFilteredUsers: Dispatch<SetStateAction<ReplayUser[]>>
}

export const useReplayStore = create<ReplayStore>((set) => ({
  filterCategory: FILTERS,
  setFilterCategory: (filterCategory) =>
    set((state) => ({
      ...state,
      filterCategory: typeof filterCategory === "function" ? filterCategory(state.filterCategory) : filterCategory,
    })),
  replays: [],
  setReplays: (replays) => set((state) => ({ ...state, replays: typeof replays === "function" ? replays(state.replays) : replays })),
  activeUser: "All Users",
  setActiveUser: (activeUser) =>
    set((state) => ({ ...state, activeUser: typeof activeUser === "function" ? activeUser(state.activeUser) : activeUser })),

  filteredUsers: [],
  setFilteredUsers: (filteredUsers) =>
    set((state) => ({
      ...state,
      filteredUsers: typeof filteredUsers === "function" ? filteredUsers(state.filteredUsers) : filteredUsers,
    })),
}))
