"use client"

import { Checkbox } from "@/components/ui/checkbox"
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { ReplayEnvironmentFilterOptions } from "./types"
import { FaCheck } from "react-icons/fa6"
import { FilterSearch } from "@/components/shared/filters"
const impactCategories = ["Error Message", "Crashed", "Did Not Load", "Drop-off"]
const environmentCategories = ["Production Only"]

interface ReplayFilterOptionsProps {
  category: string
  selectedFilterType: {
    impact: string[]
    endpoint: string[]
    environment: ReplayEnvironmentFilterOptions
  }
  handleSelect: (category: string, subCategory: string) => void
  setOpen: (open: boolean) => void
}
const ReplayFilterOptions = ({ category, selectedFilterType, handleSelect, setOpen }: ReplayFilterOptionsProps) => {
  if (category === "endpoint") {
    return <FilterSearch setOpen={setOpen} handleSelect={handleSelect} selectedFilterType={selectedFilterType} />
  }
  const categories = category === "impact" ? impactCategories : environmentCategories
  const handleClick = (item: string) => {
    handleSelect(category, item)
    setOpen(false)
  }
  return (
    <Command>
      <CommandInput placeholder="Filter" />
      <CommandList>
        <CommandGroup>
          {categories.map((item) => (
            <CommandItem key={item} value={item}>
              <div className="flex items-center space-x-2">
                {category === "impact" ? (
                  <Checkbox
                    checked={selectedFilterType.impact.includes(item)}
                    onCheckedChange={() => {
                      handleSelect(category, item)
                    }}
                  />
                ) : null}

                <div onClick={() => handleClick(item)} className="cursor-pointer">
                  <div className="text-sm px-2">{item}</div>
                </div>
                {category === "environment" && item === selectedFilterType.environment ? <FaCheck className="w-4 h-4" /> : null}
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}

export default ReplayFilterOptions
