import React, { Dispatch, SetStateAction, useState } from "react"
import { REPLAYS_FILTERS_TYPE } from "@/app/replays/constants"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ReplayEnvironmentFilterOptions } from "@/app/issues/types"
import { FilterBadge, FilterRemoveButton, FilterTitle } from "."
import RenderFilterCategoryOptions from "@/app/replays/replays-filter-category-options"
import { Button } from "@/components/ui/button"
import { useReplayStore } from "@/app/replays/store"

interface SelectedFilterType {
  impact: string[]
  endpoint: string[]
  environment: ReplayEnvironmentFilterOptions
}

interface FilterSectionProps {
  isDemo?: boolean
  filterCategory?: REPLAYS_FILTERS_TYPE
  setFilterCategory?: Dispatch<SetStateAction<REPLAYS_FILTERS_TYPE>>
  selectedFilterType?: SelectedFilterType
  setSelectedFilterType?: (filterType: SelectedFilterType) => void
  renderOptions?: (category: string, handlePopoverOpenChange: (open: boolean) => void) => JSX.Element
}

const FilterSection: React.FC<FilterSectionProps> = ({
  isDemo,
  filterCategory,
  setFilterCategory,
  selectedFilterType,
  setSelectedFilterType,
  renderOptions,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const { setActiveUser } = useReplayStore()
  const [open, setOpen] = useState(false)

  const handleClearAll = () => {
    if (filterCategory && setFilterCategory) {
      const resetCategory = Object.keys(filterCategory).reduce<REPLAYS_FILTERS_TYPE>(
        (acc, key) => ({
          ...acc,
          [key]: {
            ...filterCategory[key],
            value: key === "length" ? { value: "", unit: "seconds", type: "> Greater Than" } : "",
          },
        }),
        filterCategory,
      )
      setFilterCategory(resetCategory)
      setActiveUser("All Users")
    }

    if (selectedFilterType && setSelectedFilterType) {
      const resetFilterType = Object.keys(selectedFilterType).reduce(
        (acc, key) => ({
          ...acc,
          [key]: Array.isArray(selectedFilterType[key]) ? [] : null,
        }),
        {} as SelectedFilterType,
      )
      setSelectedFilterType(resetFilterType)
    }
  }

  if (selectedFilterType && Object.values(selectedFilterType).every((filter) => filter && filter.length === 0)) return null

  const handleFilterRemove = (key: string) => {
    if (filterCategory && setFilterCategory) {
      setFilterCategory((prevCategory) => ({
        ...prevCategory,
        [key]:
          key === "length"
            ? { ...prevCategory[key], value: { value: "", unit: "seconds", type: "> Greater Than" } }
            : { ...prevCategory[key], value: "" },
      }))
    }
    if (selectedFilterType && setSelectedFilterType) {
      const newFilter = {
        ...selectedFilterType,
        [key]: key === "environment" ? null : [],
      }
      setSelectedFilterType(newFilter)
    }
    if (key === "user") {
      setActiveUser("All Users")
    }
  }

  return (
    <div className="flex flex-wrap gap-2">
      {filterCategory &&
        Object.entries(filterCategory).map(([key, filter]) => {
          if (!isDemo && filter.isForDemoOnly) return null
          if (key === "user" && filterCategory?.user?.value === "All Users") return null
          if (key === "length" && filterCategory?.length?.value?.value === "") return null
          if (filter.value) {
            return (
              <FilterBadge key={key}>
                <FilterTitle label={filter.label} isEquality={key === "length" || key === "clickTag"} />
                <Popover
                  open={selectedCategory === key && open}
                  onOpenChange={(isOpen) => {
                    setOpen(isOpen)
                    if (!isOpen) setSelectedCategory(null)
                  }}>
                  <PopoverTrigger asChild>
                    <span
                      className="pl-3 pr-3 py-1 cursor-pointer hover:text-gray-900 hover:bg-gray-200 dark:hover:text-gray-300"
                      onClick={() => setSelectedCategory(key)}>
                      {typeof filter.value === "object"
                        ? `${filter.value.type === "> Greater Than" ? ">" : "<"} ${filter.value.value} ${filter.value.unit}`
                        : filter.value}
                    </span>
                  </PopoverTrigger>
                  <PopoverContent className="p-0 max-h-60 w-56 ml-[5rem]">
                    <RenderFilterCategoryOptions
                      category={key}
                      setOpen={setOpen}
                      filterCategory={filterCategory}
                      setFilterCategory={setFilterCategory!}
                    />
                  </PopoverContent>
                </Popover>
                <FilterRemoveButton onClick={handleFilterRemove} filterKey={key} />
              </FilterBadge>
            )
          }
          return null
        })}
      {selectedFilterType &&
        Object.entries(selectedFilterType).map(
          ([key, filterValues]) =>
            filterValues &&
            filterValues.length > 0 && (
              <FilterBadge key={key}>
                <FilterTitle label={key} />
                <span className="pl-3 pr-3 py-1 cursor-pointer hover:text-gray-900 hover:bg-gray-200 dark:hover:text-gray-300">
                  <Popover
                    open={selectedCategory === key && open}
                    onOpenChange={(isOpen) => {
                      setOpen(isOpen)
                      if (!isOpen) setSelectedCategory(null)
                    }}>
                    <PopoverTrigger asChild>
                      <span onClick={() => setSelectedCategory(key)}>
                        {key === "endpoint"
                          ? filterValues[0]
                          : key === "environment"
                            ? filterValues
                            : filterValues.length === 1
                              ? filterValues[0]
                              : `${filterValues.length} ${key}s`}
                      </span>
                    </PopoverTrigger>
                    <PopoverContent className="p-0 max-h-60 w-48 ml-[5rem]">
                      {renderOptions && renderOptions(key, (open) => setOpen(open))}
                    </PopoverContent>
                  </Popover>
                </span>
                <FilterRemoveButton onClick={handleFilterRemove} filterKey={key} />
              </FilterBadge>
            ),
        )}
      {((filterCategory &&
        Object.values(filterCategory).some((filter) =>
          typeof filter?.value === "object"
            ? filter?.value?.value
            : filter?.label === "User"
              ? filter?.value !== "All Users" && filter?.value !== ""
              : filter?.value,
        )) ||
        (selectedFilterType && Object.values(selectedFilterType).some((filter) => filter && filter.length > 0))) && (
        <Button
          onClick={handleClearAll}
          variant="ghost"
          size="sm"
          className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200">
          Clear All
        </Button>
      )}
    </div>
  )
}

export default FilterSection
